.photoAddMentor{
    position: relative;
}

.iconAddMentor{
    position: absolute;
    top:-12px;
    left: 21%;
    background-color:whitesmoke;
    border-radius: 50%;
}

.dateWidthMentor{
    width: 343px !important;
}

@media (max-width: 768px) {
    .dateWidthMentor{
        width: 285px !important;
    }
  }
.photo{
    position: relative;
}

.icon{
    position: absolute;
    top:-10px;
    left: 12%;
}

.borderBottom{
    border-bottom: 1px solid #ebedf2;
  }

  .iconColor{
    color:#f4b53f;
    font-size:10px;
}
.marginBottom{
    margin-bottom: 200px !important;
}


.tableMaxWidth{
    max-width: 500px !important;
}

i{
    cursor: -webkit-grab; cursor: grab !important;
}

.BadgeStyleCursor{
    cursor: -webkit-grab; cursor: grab !important;
   
}

.dateWidthMentorInfo{
    width: 345px !important;
}

.dateWidth{
    width: 349px !important;
}

.dateWidthDateProg{
    width: 400px !important;

}

/* .dateWidthDateProgXLR{
    width: 450px !important;
}

@media (max-width: 768px) {
    .dateWidthDateProgXLR{
        width: 300px !important;
    }
  } */

.progDates{
    position: absolute;
    top: 75%;
    right: 50%;
}
.col-cus {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: 500;
    color: black;
}

.col-cus + .col-cus {
    padding: 0 0 0 0.25em;
    border-left: 1px solid grey;
    color: #f4b53f;
}

.dashboardLoading{
    position: absolute;
    top: 55%;
    right: 50%;  
}

.dashboardText{
    font-size: 12px !important;
    color: #5c5a6d;
}
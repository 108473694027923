.makeStyles-table-43 {
  max-width: 800px;
}

.borderBottom {
  border-bottom: 1px solid #ebedf2;
}
.modalParent {
  position: relative;
}

.modalChild {
  position: absolute;
  bottom: 0;
}

.iconColor {
  color: #f4b53f;
  font-size: 10px;
}
.marginBottom {
  margin-bottom: 100px !important;
}

i {
  cursor: -webkit-grab;
  cursor: grab !important;
}

/* .BadgeStyleCursor{
    cursor: grab !important;
} */

.loaderStyle {
  position: absolute;
  top: 60%;
  right: 50%;
  /* transform: translate(-50%, -50%); */
}

.dateWidthInfo {
  width: 100% !important;
}

.childDateAddress {
  width: 100% !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #f4b53f !important;
}

.radioButtonInline {
  display: flex !important;
  flex-direction: row !important;
}

.ChildDateChange {
  width: 100% !important;
}

.childDatePermissionSlip {
  width: 100% !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.childDateProgram {
  width: 100% !important;
}

.childDateName {
  width: 100% !important;
}

.cloneDateWidth {
  width: 100% !important;
}

@media (max-width: 768px) {
  .ChildDateChange {
    width: 100% !important;
  }
  .childDatePermissionSlip {
    width: 100% !important;
  }
  .childDateProgram {
    width: 100% !important;
  }
  .dateWidthInfo {
    width: 100% !important;
  }
}

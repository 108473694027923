.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #f4b53f;
  font-size: 12px;
  /* border: 1px solid #5e0761; */

  /* text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3); */
}

.swal-button:hover {
  background-color: #f4b53f !important;
}

.pagination {
  display: flex !important;
  padding-left: 0 !important;
  list-style: none !important;
  border-radius: 0.25rem !important;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #ebedf2;
}

.pagination li a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #f4b53f;
  background-color: #fff;
  border: 1px solid #ebedf2;
  text-decoration: none;
}

.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
  /* border: 0.9px solid; */
}

.pagination li.active a {
  z-index: 3;
  color: #fff;
  background-color: #f4b53f;
  border-color: #f4b53f;
}
/* Style the active class (and buttons on mouse-over) */
.page-link:hover {
  z-index: 2;
  color: #da920c;
  text-decoration: none;
  background-color: #ebedf2;
  border-color: #ebedf2;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiButton-root.Mui-disabled {
  background-color: lightgray !important;
}

.react-tel-input .form-control {
  padding: 0.65rem 1rem !important;
  border-color: #e2e5ec !important;
  width: 100% !important;
}

.react-tel-input .selected-flag {
  display: none !important;
}

.react-tel-input .flag-dropdown {
  border: unset !important;
}

.login-btn {
  background-color: #f4b53f !important;
}

/* Hide current arrow */
.react-datepicker__navigation--years {
  border-right-color: transparent;
  text-indent: unset !important;
  color: black;
  width: max-content !important;
}
/* Display something else */
.react-datepicker__navigation--years:after {
  content: "Next";
  padding-top: 10px;
}

.react-datepicker__navigation--years-upcoming {
display: flex !important;
}
.react-datepicker__navigation--years-upcoming:after {
  content: "Previous";
  padding-top: 10px;
}

.react-datepicker__navigation
  .react-datepicker__navigation--years
  .react-datepicker__navigation--years-previous {
  text-indent: unset !important;
}




@media only screen and (max-width: 1350px) {

  .sidebar-card{
    width: 100% !important;
  }

}
.borderDown{
    text-decoration: underline;
    text-decoration-color: #f4b53f;
     text-decoration-style:inherit ;
}

.icon-color{
    color: rgb(239,31,47);
}

.kidReportContainer{
  max-width: 300px;
}

.loaderStyleReport{
  position: absolute;
  top: 50%;
  right: 50%;
}


@media (min-width: 1025px){
.kt-portlet.kt-portlet--height-fluid {
height: auto;
}}
